import React, { FC, useEffect, useRef } from 'react';
import { graphql, Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import {
  PageHeading,
  PageLayout,
  WeightPackage,
  ChallengeStories,
  WeightLossBenefits,
  HowItWorksSection,
  FAQ,
  WeightLossSteps,
  PaymentContent,
  WeightLossBannerNew,
  RenderVideoAndImage,
} from 'components';
import { SlideUpContent, SlideUpTitle } from 'components/animations';
import smoothscroll from 'smoothscroll-polyfill';

const WeightLossPage: FC = ({ data }: any) => {
  const {
    title,
    tagline,
    ctaButtonText,
    description,
    seo,
    banner,
    weightLossBenefits,
    howItWorks,
    weightLossOptions,
    challengeStoriesTitle,
    challengeStoriesTagline,
    faqs,
    weightLossSteps,
    weightlossPackage,
    videoImage,
  } = data.WeightLossSection;
  const { slug } = data.strapiStoryPage;
  const VideoGallery: any = [];
  const DesktopPlanRef = useRef(null) as any;
  useEffect(() => {
    if (videoImage?.video?.localFile.publicURL) {
      VideoGallery.push(videoImage?.video?.localFile.publicURL);
    }
  }, [VideoGallery]);
  useEffect(() => {
    document.body.classList.add('weight-loss-page');
    smoothscroll.polyfill();
    return () => {
      document.body.classList.remove('weight-loss-page');
    };
  }, []);
  const scrollPlan = () => {
    if (DesktopPlanRef.current) {
      DesktopPlanRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
      <WeightLossBannerNew banner={banner} />
      <PageLayout
        title={title}
        delaySec={0.3}
        showBrand={false}
        metaTitle={seo?.metaTitle ?? title}
        description={seo?.metaDescription}
        image={seo?.shareImage?.localFile?.publicURL}
        faqs={faqs.faqs}
      >
        <div className="container">
          <div className="title-button-wrapper">
            <SlideUpTitle delaySec={1} durationTime={1}>
              <div className="page-heading-title title-with-line">
                <ReactMarkdown>{title}</ReactMarkdown>
              </div>
            </SlideUpTitle>
            <SlideUpContent className="weightloss-page-content" delaySec={0.6}>
              <div className="weightloss-page-tagline">
                <ReactMarkdown className="md-html">{tagline}</ReactMarkdown>
              </div>
              <div className="weightloss-page-description">
                <ReactMarkdown className="md-html">{description}</ReactMarkdown>
              </div>
            </SlideUpContent>
            <SlideUpContent>
              {ctaButtonText && (
                <div className="button-wrapper">
                  <button
                    className="see-our-packages"
                    type="button"
                    onClick={() => scrollPlan()}
                  >
                    <span>{ctaButtonText}</span>
                  </button>
                </div>
              )}
            </SlideUpContent>
          </div>
        </div>
        <div className="video-container">
          <RenderVideoAndImage
            data={videoImage}
            altText={title}
            hasPlayButton
            hasBorder
            buttontype="text"
            buttonsize="large"
            videogallery={VideoGallery}
            showControls
            showModal
            showModalforMobile
          />
        </div>
        <div className="container">
          <WeightPackage weightLoss={weightLossOptions} />
          <div className="how-it-works-section">
            <PageHeading
              title={howItWorks.title}
              subtitle={howItWorks.tagline}
            />
          </div>
        </div>
        <HowItWorksSection
          howItWorks={howItWorks}
          ctaButtonText={ctaButtonText}
          scrollPlan={scrollPlan}
        />
        <WeightLossSteps data={weightLossSteps} />
        <div className="container">
          <section>
            <SlideUpTitle delaySec={1} durationTime={0.5}>
              <ReactMarkdown className="page-heading-title title-with-line">
                {weightLossBenefits.title}
              </ReactMarkdown>
            </SlideUpTitle>
            <SlideUpContent className="weightloss-page-content" delaySec={0.5}>
              <div className="weightloss-page-tagline">
                <ReactMarkdown className="md-html">
                  {weightLossBenefits.tagline}
                </ReactMarkdown>
              </div>
              <div className="weightloss-page-description">
                <ReactMarkdown className="md-html">
                  {weightLossBenefits.description}
                </ReactMarkdown>
              </div>
            </SlideUpContent>
            <WeightLossBenefits wlBenefits={weightLossBenefits.benefits} />
          </section>
        </div>
        <PaymentContent
          weightLossPackageDescription={weightlossPackage}
          weightLossPackage={data.WeightLossPackage}
          weightLossPackageService={data.WeightLossPackageService}
          // products={data.Products}
          functionUrl={data.site.siteMetadata.functionsUrl}
          DesktopPlanRef={DesktopPlanRef}
        />

        <div className="container">
          <div className="challenge-stories">
            <div className="page-heading">
              <SlideUpTitle delaySec={0.3}>
                <div className="page-heading-title title-with-line">
                  {challengeStoriesTitle}
                </div>
              </SlideUpTitle>

              <div className="row">
                <div className="col-lg-8">
                  <div className="page-heading-content">
                    <SlideUpContent
                      className="weightloss-page-content weightloss-page-challenge-content"
                      delaySec={0.5}
                    >
                      <div className="page-heading-tagline">
                        {challengeStoriesTagline}
                      </div>
                    </SlideUpContent>
                  </div>
                </div>
                <div className="col-lg-4">
                  {data.strapiStoryPage.slug && (
                    <div className="btn-link-wrapper">
                      <Link to={`/${slug}`} className="challenge-stories-link">
                        See More <span>&gt;</span>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="challenge-stories-line" />
            </div>
          </div>
        </div>
        <ChallengeStories
          challengeStories={data.ChallengeStories}
          characterLimit={220}
        />
        <div className="container">
          <PageHeading
            title={faqs.title}
            subtitle={faqs.tagline}
            delaySec={-0.7}
            durationTime={0}
          />
          <FAQ Faqs={faqs.faqs} />
        </div>
      </PageLayout>
    </>
  );
};

export default WeightLossPage;
export const query = graphql`
  query WeightLossPageQuery {
    strapiStoryPage {
      slug
    }
    WeightLossSection: strapiWeightLossPage {
      title
      tagline
      ctaButtonText
      description
      ctaButtonText
      challengeStoriesTitle
      challengeStoriesTagline
      videoImage {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
        video {
          localFile {
            publicURL
          }
        }
      }
      banner {
        bannerImage {
          localFile {
            publicURL
          }
        }
        bannerLeftBottomImage {
          localFile {
            publicURL
          }
        }
        bannerLeftTopText
        bannerRightBottomImage {
          localFile {
            publicURL
          }
        }
        bannerRightTopImage {
          localFile {
            publicURL
          }
        }
        bannerTagline
        bannerTitle
      }
      faqs {
        title
        tagline
        faqs {
          title
          id
          answer
        }
      }
      weightLossOptions {
        description
        id
        tagline
        title
        active
        logoImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
      howItWorks {
        title
        tagline
        weightLossJourneyTitle
        weightLossJourney {
          description
          id
          title
          image {
            localFile {
              publicURL
            }
          }
        }
      }
      weightLossBenefits {
        title
        tagline
        description
        benefits {
          description
          title
          icon
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 400, height: 400)
              }
            }
          }
        }
      }
      weightlossPackage {
        purchaseButtonText
        tagline
        title
        challengeTermText
        guaranteeTitle
        guaranteeLogo {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 100)
            }
          }
        }
        inventoryLevel
        nextIntaketitle
        nextIntakedate
        logoImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      weightLossSteps {
        description
        enquiryText
        tagline
        title
        stepsToWeightLoss {
          title
          id
          icon
          description
        }
      }
    }
    ChallengeStories: allStrapiStories(
      sort: { fields: sortDate, order: DESC }
    ) {
      edges {
        node {
          sortDate
          id
          title
          description
          weightLoss
          starRating
          clientDetails
          videoimage {
            image {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 800)
                }
                publicURL
              }
            }
            video {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        functionsUrl
      }
    }
    WeightLossPackage: allStrapiWeightlossPackage(
      sort: { fields: strapiId, order: ASC }
    ) {
      edges {
        node {
          popular
          price
          weeklyPrice
          priceInstallmentText
          hummTotalPrice
          id
          tagline
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    WeightLossPackageService: allStrapiWeightLossPackageServices(
      sort: { fields: strapiId, order: ASC }
    ) {
      edges {
        node {
          id
          title
          icon
          weight_loss_keys {
            description
            infoPopup {
              description
              id
              title
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(height: 500)
                  }
                }
              }
            }
            weightLossKeyPoint {
              id
              availableText
              included
              weightloss_package {
                title
              }
            }
          }
        }
      }
    }
  }
`;
